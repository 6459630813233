import { round } from '@/helpers';

type EstimateWorkflowSummaryDTO = {
    optionalsSectionsIdsAdded: any[];
    lastCompleteSectionId: number | null;
    discountableItemTotal: number;
    nonDiscountableItemTotal: number;
    totalCost: number;
    discountPercent: number;
    marginPercent: number;
    promotion: number;
    fee: number;
};

class EstimateWorkflowSummary {
    optionalsSectionsIdsAdded: any[];
    lastCompleteSectionId: number | null;
    discountableItemTotal: number;
    nonDiscountableItemTotal: number;
    totalCost: number;
    discountPercent: number;
    marginPercent: number;
    promotion: number;
    fee: number;

    constructor(dto: Partial<EstimateWorkflowSummaryDTO> = {}) {
        this.optionalsSectionsIdsAdded = dto.optionalsSectionsIdsAdded ?? [];
        this.lastCompleteSectionId = dto.lastCompleteSectionId ?? null;
        this.discountableItemTotal = dto.discountableItemTotal ?? 0;
        this.nonDiscountableItemTotal = dto.nonDiscountableItemTotal ?? 0;
        this.totalCost = dto.totalCost ?? 0;
        this.discountPercent = dto.discountPercent ?? 0;
        this.marginPercent = dto.marginPercent ?? 0;
        this.promotion = dto.promotion ?? 0;
        this.fee = dto.fee ?? 0;
    }

    get margin() {
        return round(this.totalWithPromotion - this.totalCost, 3);
    }

    get itemTotal() {
        return this.nonDiscountableItemTotal + this.discountableItemTotal;
    }

    get discount() {
        return round(this.discountableItemTotal * this.discountPercent, 3);
    }

    get discountPercentFromMargin() {
        if (this.marginPercent === 0) {
            return this.discountPercent;
        }

        const discount = this.itemTotal - this.totalWithMargin;
        const discountPercent = discount / this.itemTotal;

        return discountPercent;
    }

    get total() {
        const aux = round(this.itemTotal - this.discount, 3);
        return round(aux, 2);
    }

    get totalWithPromotion() {
        const aux = round(this.total + this.fee - this.promotion, 3);
        return round(aux, 2);
    }

    get totalWithMargin() {
        if (this.marginPercent === 0) {
            return this.total;
        } else {
            return round(this.totalCost / (1 - this.marginPercent), 3);
        }
    }
}

export default EstimateWorkflowSummary;
