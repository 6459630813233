/* eslint-disable no-unused-vars */
import { url as urlHelper } from '@/helpers';
import { getDb } from '@/idbInit';
import * as localChanges from '@/idbLocalChanges';
import * as localIds from '@/idbLocalIds';
import Estimate from '@/models/Estimate';
import LocalChange from '@/models/LocalChange';
import LocalChangeState from '@/models/LocalChangeState';
import { fetchWrap, notFoundResponse, offlineResponse } from '../_helpers';
import { AxiosError } from 'axios';

interface EstimateData {
    id: number;
    leadId?: number;
    parentItemId?: number | null;
    documents?: any[];
    items?: EstimateData[];
}

interface DocumentData {
    id: number;
    leadId?: number;
    [key: string]: any;
}

async function assignLocalIds(data: EstimateData) {
    const itemMap: Record<number, EstimateData> = {};
    const childItemsMap: Record<number, EstimateData[]> = {};

    for (const item of data.items || []) {
        itemMap[item.id] = item;
        if (item.parentItemId !== undefined && item.parentItemId !== null) {
            if (!Array.isArray(childItemsMap[item.parentItemId])) {
                childItemsMap[item.parentItemId] = [];
            }
            childItemsMap[item.parentItemId].push(item);
        }
    }

    const idb = await getDb();
    for (const item of data.items || []) {
        const oldId = item.id;
        if (oldId < 0 && -oldId % 2 === 0) {
            const newId = await localIds.getNewId(idb, 'estimate-items');
            item.id = newId;
            delete itemMap[oldId];
            itemMap[newId] = item;

            const children = childItemsMap[oldId];
            if (Array.isArray(children)) {
                for (const child of children) {
                    child.parentItemId = newId;
                }
                delete childItemsMap[oldId];
                childItemsMap[newId] = children;
            }
        }
    }
}

async function addDocumentsToRawEstimate(estimate: EstimateData, documents: DocumentData[]): Promise<void> {
    estimate.documents = JSON.parse(JSON.stringify(documents));
}

export default {
    async getAllByLeadId(leadId: number) {
        const idb = await getDb();
        leadId = await localIds.mapLocalId(idb, 'leads', leadId);
        const query: Record<string, number> = {};

        if (typeof leadId === 'number') {
            query.leadId = leadId;
        }

        const url = urlHelper('/api/estimates', query);
        let response;

        try {
            response = await fetchWrap(url);
        } catch {
            const data = await idb.getAllFromIndex('estimates', 'leadId', leadId);
            return data.map((x) => new Estimate(x));
        }

        if (response?.status < 300 && response?.status >= 200) {
            const apiData = response.data;
            const data: EstimateData[] = Array.from(apiData);
            const idbDataMap = (await idb.getAllFromIndex('estimates', 'leadId', leadId)).reduce((obj, x) => {
                obj[x.id] = x;
                return obj;
            }, {} as Record<number, EstimateData>);

            const estimateChanges = (await idb.getAllFromIndex('local-changes', 'storeName', 'estimates')).reduce(
                (obj, x) => {
                    obj[x.id] = x;
                    return obj;
                },
                {} as Record<number, LocalChange>
            );

            for (let i = 0; i < data.length; i++) {
                const apiItem = data[i];
                const changes = estimateChanges[apiItem.id];
                if (changes && changes.state === LocalChangeState.modified) {
                    data[i] = idbDataMap[apiItem.id];
                } else if (changes && changes.state === LocalChangeState.deleted) {
                    data.splice(i, 1);
                    i--;
                } else {
                    await idb.put('estimates', apiItem, apiItem.id);
                }
            }

            Object.values(estimateChanges)
                .filter((x: any) => x.state === LocalChangeState.added && idbDataMap[x.id])
                .forEach((x: any) => data.push(x));

            return data.map((x) => new Estimate(x));
        } else {
            return response;
        }
    },

    async getById(id: number) {
        const idb = await getDb();
        const change = await idb.get('local-changes', LocalChange.getKey('estimates', id));
        if (change instanceof LocalChange) {
            if (change.state === LocalChangeState.added || change.state === LocalChangeState.modified) {
                const data = await idb.get('estimates', id);
                if (data) return new Estimate(data);
            } else if (change.state === LocalChangeState.deleted) {
                return notFoundResponse();
            }
        }

        try {
            const response = await fetchWrap('/api/estimates/' + id);
            if (response?.status < 300 && response?.status >= 200) {
                const data = response.data;
                await idb.put('estimates', data, data.id);
                return new Estimate(data);
            } else {
                return response;
            }
        } catch {
            const data = await idb.get('estimates', id);
            return data ? new Estimate(data) : offlineResponse();
        }
    },

    async create(model: EstimateData) {
        const idb = await getDb();
        model.leadId = await localIds.mapLocalId(idb, 'leads', model.leadId as number);
        for (const doc of model.documents as unknown as DocumentData[]) {
            doc.id = await localIds.mapLocalId(idb, 'estimate-documents', doc.id);
            doc.leadId = model.leadId;
        }

        const documents = model.documents;
        model = { ...model };
        delete model.documents;

        try {
            const response = await fetchWrap('/api/estimates/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(model),
            });

            if (response?.status < 300 && response?.status >= 200) {
                const data = response.data;
                await localIds.addLocalIdMap(idb, 'estimates', model.id, data.id);
                await idb.delete('estimates', model.id);
                await idb.put('estimates', data, data.id);
                await localChanges.deleteChange(idb, LocalChange.getKey('estimates', model.id));
                return new Estimate(data);
            } else {
                return response;
            }
        } catch {
            const data = { ...model, id: await localIds.getNewId(idb, 'estimates') };
            await addDocumentsToRawEstimate(data, documents as unknown as DocumentData[]);
            await idb.put('estimates', data, data.id);
            await localChanges.add(
                idb,
                new LocalChange({ storeName: 'estimates', id: data.id, state: LocalChangeState.added })
            );
            return new Estimate(data);
        }
    },

    async update(model: EstimateData) {
        const idb = await getDb();
        model.id = await localIds.mapLocalId(idb, 'estimates', model.id);
        model.leadId = await localIds.mapLocalId(idb, 'leads', model.leadId as number);

        for (const doc of model.documents as unknown as DocumentData[]) {
            doc.id = await localIds.mapLocalId(idb, 'estimate-documents', doc.id);
            doc.leadId = model.leadId;
        }

        const documents: any = model.documents;
        model = { ...model };
        delete model.documents;

        try {
            const response = await fetchWrap('/api/estimates/' + model.id, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(model),
            });

            if (response?.status < 300 && response?.status >= 200) {
                const data = response.data;
                await idb.put('estimates', data, data.id);
                await localChanges.deleteChange(idb, LocalChange.getKey('estimates', model.id));
                return new Estimate(data);
            } else {
                return response;
            }
        } catch {
            const data = { ...model, id: model.id };
            await assignLocalIds(data);
            await addDocumentsToRawEstimate(data, documents);
            await idb.put('estimates', data, data.id);
            await localChanges.add(
                idb,
                new LocalChange({ storeName: 'estimates', id: data.id, state: LocalChangeState.modified })
            );
            return new Estimate(data);
        }
    },

    async deleteById(id: number) {
        const idb = await getDb();
        const mappedId = await localIds.mapLocalId(idb, 'estimates', id);
        try {
            const response = await fetchWrap(`/api/estimates/${mappedId}`, { method: 'DELETE' });
            if (response?.status >= 200 && response?.status < 300) {
                await idb.delete('estimates', mappedId);
                await localChanges.deleteChange(idb, LocalChange.getKey('estimates', mappedId));
                return true;
            }
            return response;
        } catch (errorAxios: AxiosError | any) {
            const error = errorAxios as AxiosError;
            if (error?.response?.status === 404) {
                await idb.delete('estimates', mappedId);
                await localChanges.deleteChange(idb, LocalChange.getKey('estimates', mappedId));
                return true;
            }
            if (error?.response?.status === 409) {
                await localChanges.deleteChange(idb, LocalChange.getKey('estimates', mappedId));
                return false;
            }
            const hasReferences = (await idb.countFromIndex('estimate-documents', 'estimateId', mappedId)) > 0;
            if (hasReferences) {
                return false;
            }
            await idb.delete('estimates', mappedId);
            await localChanges.add(
                idb,
                new LocalChange({ storeName: 'estimates', id: mappedId, state: LocalChangeState.deleted })
            );
            return true;
        }
    },
};
